import React, { useEffect, useState } from "react";
import type { HeadFC } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import cls from "classnames";

import "../styles/index.css";

import Logos from "../components/Logos";
import Slider from "../components/Slider";
import { SEO } from "../components/SEO";

const IMAGE_SCALE = 0.5;

const IndexPage = () => {
  useEffect(() => {
    function textAnimate(e: MouseEvent) {
      const imgX = -(window.innerWidth / 5 - e.pageX) / 90;
      const imgY = (window.innerHeight / 5 - e.pageY) / 50;
      const content = document.querySelector(".content") as HTMLDivElement;
      content.style.transform =
        "rotateY(" + imgX + "deg) rotateX(" + imgY + "deg)";
    }
    // document.addEventListener("mousemove", textAnimate);
    return () => {
      // document.removeEventListener("mousemove", textAnimate);
    };
  }, []);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  return (
    <main className="w-full h-full bg-zinc-700 overflow-hidden relative">
      <div className="w-full h-full content pointer-events-none">
        <div className="absolute -inset-20">
          <Slider
            imagesLoaded={() => {
              setImagesLoaded(true);
            }}
          />
        </div>
        <div className="text-content w-full h-full absolute inset-0">
          <div className="w-full h-full flex items-center justify-center p-6 flex-col">
            <div className="overflow-hidden">
              <StaticImage
                className={cls(
                  { "-skew-y-12 translate-y-56 opacity-0": !imagesLoaded },
                  "transition-transform delay-100 duration-700"
                )}
                src="../images/yawa-logo.png"
                objectFit="cover"
                placeholder="tracedSVG"
                alt="Background"
                width={1498 * IMAGE_SCALE}
                height={466 * IMAGE_SCALE}
              />
            </div>
            <div className="max-w-screen-sm grid grid-cols-2 gap-5 text-xs sm:text-sm md:text-base pt-6">
              <a
                href="https://www.google.com/maps/place/Sandton+City/@-26.1088467,28.0527198,15z/data=!4m5!3m4!1s0x0:0x6e3b5ac49f8d784b!8m2!3d-26.1088467!4d28.0527198"
                target="_blank"
                className="pointer-events-auto block overflow-hidden"
              >
                <p className="text-white text-center">
                  AMIRI
                  <br />
                  Sandton City Johannesburg
                  <br />
                  Monday to Friday, 9-7 PM
                  <br />
                  Sunday, 9-6 PM
                </p>
              </a>
              <a
                href="https://www.google.com/maps/place/Sandton+City/@-26.1088467,28.0527198,15z/data=!4m5!3m4!1s0x0:0x6e3b5ac49f8d784b!8m2!3d-26.1088467!4d28.0527198"
                target="_blank"
                className="pointer-events-auto block overflow-hidden"
              >
                <p className="text-white text-center">
                  AMIRI
                  <br />
                  V&A Waterfront
                  <br />
                  Cape Town
                  <br />
                  Monday to Sunday
                  <br />
                  9-10 PM
                </p>
              </a>
            </div>
            {/*<a
              href="https://www.google.com/maps/place/Sandton+City/@-26.1088467,28.0527198,15z/data=!4m5!3m4!1s0x0:0x6e3b5ac49f8d784b!8m2!3d-26.1088467!4d28.0527198"
              target="_blank"
              className="pointer-events-auto block overflow-hidden"
            >
              <StaticImage
                className={cls(
                  { "-skew-y-12 translate-y-56 opacity-0": !imagesLoaded },
                  "transition-transform delay-100 duration-700"
                )}
                src="../images/sandton-logo.png"
                placeholder="tracedSVG"
                alt="Sandton"
                objectFit="contain"
                width={312 * IMAGE_SCALE}
                height={30 * IMAGE_SCALE}
              />
            </a>*/}
          </div>
          <div
            className={cls(
              " w-full h-full flex flex-col justify-between transition-all opacity-100 delay-100 duration-1000 absolute inset-0",
              { "scale-105 opacity-0": !imagesLoaded }
            )}
          >
            <div className="w-full p-6 flex justify-end items-end pointer-events-auto fixed top-0 flex-col gap-3">
              <a href="mailto:info@yawa.africa" target="_blank">
                <StaticImage
                  src="../images/email.png"
                  placeholder="tracedSVG"
                  objectFit="contain"
                  alt="Instagram"
                  width={48 * IMAGE_SCALE}
                  height={38 * IMAGE_SCALE}
                />
              </a>
              <a href="tel:+27657313572" target="_blank">
                <StaticImage
                  src="../images/call.png"
                  placeholder="tracedSVG"
                  objectFit="contain"
                  alt="Instagram"
                  width={46 * IMAGE_SCALE}
                  height={46 * IMAGE_SCALE}
                />
              </a>
              <a href="https://www.instagram.com/yawa.africa" target="_blank">
                <StaticImage
                  src="../images/instagram-logo.png"
                  placeholder="tracedSVG"
                  objectFit="contain"
                  alt="Instagram"
                  width={46 * IMAGE_SCALE}
                  height={46 * IMAGE_SCALE}
                />
              </a>
            </div>
            {/* <Logos /> */}
          </div>
        </div>
      </div>
      <div
        className={cls(
          "inset-0 absolute bg-black transition-all duration-1000 justify-center items-center flex",
          {
            "pointer-events-none opacity-0": imagesLoaded,
          }
        )}
      >
        <h2 className="text-white text-xl">Loading your Yawa Experience</h2>
      </div>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO />;
